var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.editedItem)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.editedItem.TermName)+", Week "+_vm._s(_vm.editedItem.WeekID)+", "+_vm._s(_vm.editedItem.HomeworkYear)+", "+_vm._s(_vm.editedItem.SubjectName)+" ")]):_vm._e(),(_vm.editedItem)?_c('v-card-subtitle',[_vm._v(" Student Number: "+_vm._s(_vm.editedItem.StudentID)+", Student Name: "+_vm._s(_vm.editedItem.GivenName)+" "+_vm._s(_vm.editedItem.Surname)+" ")]):_vm._e(),_c('v-card-text',[_c('div',[(_vm.editedItem.IsMarked)?_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" This homework has already been marked. ")]):_vm._e()],1),_c('div',{staticClass:"text-center d-flex justify-center pa-2"},[_c('v-avatar',{attrs:{"tile":"","color":_vm.color}}),_c('v-avatar',{attrs:{"tile":""},on:{"click":_vm.undo}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":hover? 'blue' : 'black'}},on),[_vm._v("fas fa-undo-alt fa-lg")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Undo")])])],1),_c('v-avatar',{attrs:{"tile":""},on:{"click":_vm.redo}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":hover? 'blue' : 'black'}},on),[_vm._v("fas fa-redo-alt fa-lg")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Redo")])])],1),_c('v-avatar',{attrs:{"tile":""},on:{"click":_vm.clear}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":hover? 'blue' : 'black'}},on),[_vm._v("fas fa-trash-alt fa-lg")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Delete selected object")])])],1),_c('v-avatar',{attrs:{"tile":""},on:{"click":function($event){return _vm.setTool('freeDrawing')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.currentActiveMethod === 'freeDrawing' || hover? 'blue' : 'black'}},on),[_vm._v("fas fa-marker fa-lg")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Marker")])])],1),_c('v-avatar',{attrs:{"tile":""},on:{"click":function($event){return _vm.setPenTool()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.currentActiveMethod === 'freeThinDrawing' || hover? 'blue' : 'black'}},on),[_vm._v("fas fa-pen-fancy fa-lg")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Pen")])])],1),_c('v-avatar',{attrs:{"tile":""},on:{"click":function($event){return _vm.setTool('text', {fontSize: 16})}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.currentActiveMethod === 'text' || hover? 'blue' : 'black'}},on),[_vm._v("fas fa-font fa-lg")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Text")])])],1),_c('v-avatar',{attrs:{"tile":""},on:{"click":function($event){return _vm.setTool('circle')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.currentActiveMethod === 'circle' || hover? 'blue' : 'black'}},on),[_vm._v("far fa-circle fa-lg")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Circle")])])],1),_c('v-avatar',{attrs:{"tile":""},on:{"click":function($event){return _vm.setTool('rect')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.currentActiveMethod === 'rect' || hover? 'blue' : 'black'}},on),[_vm._v("far fa-square fa-lg")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Rectangle")])])],1),_c('v-avatar',{attrs:{"tile":""},on:{"click":function($event){return _vm.setTool('arrow')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.currentActiveMethod === 'arrow' || hover? 'blue' : 'black'}},on),[_vm._v("fas fa-long-arrow-alt-down fa-lg")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Arrow")])])],1),_c('v-avatar',{attrs:{"tile":""},on:{"click":function($event){return _vm.setTool('selectMode')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.currentActiveMethod === 'selectMode' || hover? 'blue' : 'black'}},on),[_vm._v("fas fa-arrows-alt fa-lg")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Select & Move")])])],1),_c('v-avatar',{attrs:{"tile":""},on:{"click":_vm.rotateImage}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":hover? 'blue' : 'black'}},on),[_vm._v("mdi-rotate-right-variant")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Rotate")])])],1),_c('v-avatar',{attrs:{"tile":""},on:{"click":_vm.saveImage}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"x-large":"","color":hover? 'blue' : 'black'}},on),[_vm._v("fas fa-save fa-lg")])]}}],null,true)})]}}])},[_c('span',[_vm._v("Click here to save your changes")])])],1)],1),_c('v-row',{staticClass:"text-center d-flex justify-center pa-2"},_vm._l((_vm.stickers),function(item,index){return _c('v-col',{key:index,staticClass:"d-flex child-flex",attrs:{"cols":"3","md":"2","lg":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',_vm._g({ref:("image" + index),refInFor:true,attrs:{"src":item.src,"aspect-ratio":"2","contain":"","gradient":hover? 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)' : ''},on:{"click":function($event){return _vm.addSticker(index)}}},on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("Add sticker")])])],1)}),1),_c('div',{staticClass:"text-center d-flex justify-center mb-6 pa-2"},[_c('Editor',{ref:"editor",attrs:{"canvasWidth":_vm.canvasWidth,"canvasHeight":_vm.canvasHeight}}),_c('div',{staticClass:"pa-6"},[_c('div',{staticClass:"color-container red",on:{"click":function($event){return _vm.changeColor('red')}}}),_c('div',{staticClass:"color-container yellow",on:{"click":function($event){return _vm.changeColor('yellow')}}}),_c('div',{staticClass:"color-container purple",on:{"click":function($event){return _vm.changeColor('purple')}}}),_c('div',{staticClass:"color-container green",on:{"click":function($event){return _vm.changeColor('green')}}}),_c('div',{staticClass:"color-container orange",on:{"click":function($event){return _vm.changeColor('orange')}}}),_c('div',{staticClass:"color-container pink lighten-4",on:{"click":function($event){return _vm.changeColor('pink lighten-4')}}}),_c('div',{staticClass:"color-container blue",on:{"click":function($event){return _vm.changeColor('blue')}}}),_c('div',{staticClass:"color-container black",on:{"click":function($event){return _vm.changeColor('black')}}})])],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('div',{staticClass:"text-center"},[_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }