<template>
  <v-card>
    <v-card-title v-if="editedItem">
      {{editedItem.TermName}}, Week {{editedItem.WeekID}}, {{editedItem.HomeworkYear}}, {{editedItem.SubjectName}}
    </v-card-title>
    <v-card-subtitle v-if="editedItem">
      Student Number: {{editedItem.StudentID}}, Student Name: {{editedItem.GivenName}} {{editedItem.Surname}}
    </v-card-subtitle>
    <v-card-text>
      <div>
        <v-alert v-if="editedItem.IsMarked" type="info"> This homework has already been marked. </v-alert>
      </div>
      <div class="text-center d-flex justify-center pa-2">
        <v-avatar tile :color="color">
        </v-avatar>
        <v-avatar tile @click="undo">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="hover? 'blue' : 'black'" v-on="on">fas fa-undo-alt fa-lg</v-icon>
              </v-hover>
            </template>
            <span>Undo</span> 
          </v-tooltip>   
        </v-avatar>
        <v-avatar tile @click="redo">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="hover? 'blue' : 'black'" v-on="on">fas fa-redo-alt fa-lg</v-icon>
              </v-hover>
            </template>
            <span>Redo</span> 
          </v-tooltip>    
        </v-avatar>
        <v-avatar tile @click="clear">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="hover? 'blue' : 'black'" v-on="on">fas fa-trash-alt fa-lg</v-icon>
              </v-hover>
            </template>
            <span>Delete selected object</span> 
          </v-tooltip>    
        </v-avatar>
        <v-avatar tile @click="setTool('freeDrawing')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="currentActiveMethod === 'freeDrawing' || hover? 'blue' : 'black'" v-on="on">fas fa-marker fa-lg</v-icon>
              </v-hover>
            </template>
            <span>Marker</span> 
          </v-tooltip>     
        </v-avatar>
        <v-avatar tile @click="setPenTool()">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="currentActiveMethod === 'freeThinDrawing' || hover? 'blue' : 'black'" v-on="on">fas fa-pen-fancy fa-lg</v-icon>
              </v-hover>
            </template>
            <span>Pen</span> 
          </v-tooltip>      
        </v-avatar>
        <v-avatar tile @click="setTool('text', {fontSize: 16})">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="currentActiveMethod === 'text' || hover? 'blue' : 'black'" v-on="on">fas fa-font fa-lg</v-icon>
              </v-hover>
            </template>
            <span>Text</span> 
          </v-tooltip>      
        </v-avatar>
        <v-avatar tile @click="setTool('circle')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="currentActiveMethod === 'circle' || hover? 'blue' : 'black'" v-on="on">far fa-circle fa-lg</v-icon>
              </v-hover>
            </template>
            <span>Circle</span> 
          </v-tooltip>        
        </v-avatar>
        <v-avatar tile @click="setTool('rect')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="currentActiveMethod === 'rect' || hover? 'blue' : 'black'" v-on="on">far fa-square fa-lg</v-icon>
              </v-hover>
            </template>
            <span>Rectangle</span> 
          </v-tooltip>      
        </v-avatar>
        <v-avatar tile @click="setTool('arrow')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="currentActiveMethod === 'arrow' || hover? 'blue' : 'black'" v-on="on">fas fa-long-arrow-alt-down fa-lg</v-icon>
              </v-hover>
            </template>
            <span>Arrow</span> 
          </v-tooltip>       
        </v-avatar>
        <v-avatar tile @click="setTool('selectMode')">
           <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="currentActiveMethod === 'selectMode' || hover? 'blue' : 'black'" v-on="on">fas fa-arrows-alt fa-lg</v-icon>
              </v-hover>
            </template>
            <span>Select & Move</span> 
          </v-tooltip>     
        </v-avatar>
        <v-avatar tile @click="rotateImage">
           <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="hover? 'blue' : 'black'" v-on="on">mdi-rotate-right-variant</v-icon>
              </v-hover>
            </template>
            <span>Rotate</span> 
          </v-tooltip>    
        </v-avatar>
        <v-avatar tile @click="saveImage">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-icon x-large :color="hover? 'blue' : 'black'" v-on="on">fas fa-save fa-lg</v-icon>
              </v-hover>
            </template>
            <span>Click here to save your changes</span> 
          </v-tooltip>    
        </v-avatar>
      </div>
      <v-row class="text-center d-flex justify-center pa-2">
        <v-col v-for="(item, index) in stickers" :key="index" class="d-flex child-flex" cols="3" md="2" lg="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-img @click="addSticker(index)" :ref="`image${index}`" :src="item.src" aspect-ratio="2" contain v-on="on" 
                :gradient="hover? 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)' : ''">
                </v-img>
              </v-hover>
            </template>
            <span>Add sticker</span> 
          </v-tooltip>  
        </v-col>
      </v-row>
      <div class="text-center d-flex justify-center mb-6 pa-2">
        <Editor :canvasWidth="canvasWidth" :canvasHeight="canvasHeight" ref="editor"/>     
        <div class="pa-6">
          <div class="color-container red" @click="changeColor('red')"></div>
          <div class="color-container yellow" @click="changeColor('yellow')"></div>
          <div class="color-container purple" @click="changeColor('purple')"></div>
          <div class="color-container green" @click="changeColor('green')"></div>
          <div class="color-container orange" @click="changeColor('orange')"></div>
          <div class="color-container pink lighten-4" @click="changeColor('pink lighten-4')"></div>
          <div class="color-container blue" @click="changeColor('blue')"></div>
          <div class="color-container black" @click="changeColor('black')"></div>
        </div>
      </div>
    </v-card-text>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
        {{ snackbarText }}
    </v-snackbar>
    <div class="text-center">
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </v-card>
</template>

<script>
import { httpClient } from '../services/http-client';
import { ImageDataConverter } from '../services/ImageDataConverter';
import Editor from 'vue-image-markup';
import { fabric } from 'fabric';

export default {
  name: 'EditImage',
  props: {
    markedOption: String,
    editedItem: Object,
    canvasWidth:{
      default: 800
    },
    canvasHeight:{
      default: 1000
    }
  },
  components: {
    Editor,
  },
  data () {
    return {
      isLoading: false,
      currentActiveMethod: null,
      params: {},
      color: "red",
      image: null,
      croppedImage: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      timeout: 6000,
      isRedoing: false,
      history: [],
      stickers: [
        { src: 'https://nsdcweb-images.s3-ap-southeast-2.amazonaws.com/100x100px+Good+Job.png' },
        { src: 'https://nsdcweb-images.s3-ap-southeast-2.amazonaws.com/100x100px+Good+Work.png' },
        { src: 'https://nsdcweb-images.s3-ap-southeast-2.amazonaws.com/100x100px+Great+Job.png' },
        { src: 'https://nsdcweb-images.s3-ap-southeast-2.amazonaws.com/100x100px+Top+Effort.png' },
        { src: 'https://nsdcweb-images.s3-ap-southeast-2.amazonaws.com/100x100px+Super+Star!+BOY.png' },
        { src: 'https://nsdcweb-images.s3-ap-southeast-2.amazonaws.com/100x100px+Super+Star!+GIRL.png' },
        { src: 'https://nsdcweb-images.s3-ap-southeast-2.amazonaws.com/100x100px+WOW!+Keep+Up+The+Good+Work!.png' },
        { src: 'https://nsdcweb-images.s3-ap-southeast-2.amazonaws.com/100x100px+thumbs+up!.png' },
        { src: `https://nsdcweb-images.s3-ap-southeast-2.amazonaws.com/You're+A+Star!.png` },
      ],
    }
  },
  async mounted() {
    try {
      this.isLoading = true;
      if (this.editedItem && this.editedItem.HomeworkID) {
        const response = await httpClient.get(`/homework/image/${this.editedItem.HomeworkID}`, { responseType:"arraybuffer" });
        let data = btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        this.image = `data:${response.headers['content-type'].toLowerCase()};base64,${data}`
        //this.$refs.editor.setBackgroundImage(this.image);
        
        let img = new Image();
        this.$refs.editor.toDataUrl(this.image, (dataUri) => {
            img.src = dataUri;
            if (img.height < img.width) {
              var currentW = this.$refs.editor.canvas.getWidth();
              var currentH = this.$refs.editor.canvas.getHeight();
              this.$refs.editor.canvas.setWidth(currentH);
              this.$refs.editor.canvas.setHeight(currentW);
              this.$refs.editor.canvas.renderAll();
            }
            
            let inst = this.$refs.editor;
            img.onload = function () {
                let image = new fabric.Image(img);
                // get the scale
                var scale = Math.min(inst.canvas.getWidth() / image.width, inst.canvas.getHeight() / image.height);
                // get the top left position of the image
                var x = (inst.canvas.getWidth() / 2) - (image.width / 2) * scale;
                var y = (inst.canvas.getHeight() / 2) - (image.height / 2) * scale;
                image.left = x;
                image.top = y;
                image.scaleX = scale;
                image.scaleY = scale;
                inst.canvas.setBackgroundImage(image, inst.canvas.renderAll.bind(inst.canvas));
                inst.canvas.renderAll();
            }
        });

        this.$refs.editor.changeColor(this.color);
        this.setPenTool();

        this.$refs.editor.canvas.on('object:added',function(){
          if(!this.isRedoing){
            this.history = [];
          }
          this.isRedoing = false;
        });
      }
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    changeColor(colorHex){
      if (this.currentActiveMethod === "text") {
        if (this.$refs.editor.canvas.getActiveObjects().length > 0)
          return;  
      }
      this.color = colorHex;
      this.$refs.editor.changeColor(colorHex.replace('lighten-4', ''));
      if (this.currentActiveMethod === "freeThinDrawing") 
        this.setPenTool();
      else if (this.currentActiveMethod === "text")
        this.setTool("text", {fontSize: 16});
    },
    downloadImage(){
      let image = this.$refs.editor.saveImage();
      this.saveImageAsFile(image)
    },
    saveImageAsFile(base64) {
      let link = document.createElement("a");
      link.setAttribute("href", base64);
      link.setAttribute("download", this.editedItem.StudentID);
      link.click();
    },
    setTool(type,params){
      this.currentActiveMethod = type;
      this.$refs.editor.set(type,params)
    },
    setPenTool() {
      this.currentActiveMethod = "freeThinDrawing";
      this.$refs.editor.set("freeDrawing", {strokeWidth: 2});
    },
    clear(){
      this.currentActiveMethod = this.clear;
      this.$refs.editor.canvas.getActiveObjects().forEach((obj) => {
        this.$refs.editor.canvas.remove(obj)
      });
      this.$refs.editor.canvas.discardActiveObject().renderAll()      
    },
    undo(){
      this.currentActiveMethod = this.undo;
      if(this.$refs.editor.canvas._objects.length>0){
        this.history.push(this.$refs.editor.canvas._objects.pop());
        this.$refs.editor.canvas.renderAll();
      }
    },
    redo(){
      this.currentActiveMethod = this.redo;
      if(this.history.length>0){
        this.isRedoing = true;
        this.$refs.editor.canvas.add(this.history.pop());
      }
    },
    close() {
      this.$emit('cancel');
    },
    addSticker(index){
      fabric.Image.fromURL(this.stickers[index].src + '?v=' + Math.random(), (oImg) => {
        oImg.scale(0.8);
        this.$refs.editor.canvas.add(oImg);
      }, {
        crossOrigin: 'anonymous'
      });
      this.setTool('selectMode');
      this.$refs.editor.canvas.renderAll();
    },
    rotateImage () {
      var currentW = this.$refs.editor.canvas.getWidth();
      var currentH = this.$refs.editor.canvas.getHeight();
      this.$refs.editor.canvas.setWidth(currentH);
      this.$refs.editor.canvas.setHeight(currentW);

      const degrees = 90;
      let canvasCenter = new fabric.Point(this.$refs.editor.canvas.getWidth() / 2, this.$refs.editor.canvas.getHeight() / 2) // center of canvas
      let radians = fabric.util.degreesToRadians(degrees)

      const backgroundImage = this.$refs.editor.canvas.backgroundImage;     
      let objectOrigin = new fabric.Point(backgroundImage.left, backgroundImage.top)
      let new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, radians)
      
      if (currentW > currentH)
      {
        backgroundImage.top = new_loc.y - 100
        backgroundImage.left = new_loc.x - 100
      } else {
        backgroundImage.top = new_loc.y + 100
        backgroundImage.left = new_loc.x + 100
      }

      backgroundImage.angle += degrees //rotate each object by the same angle
      backgroundImage.setCoords()

      this.$refs.editor.canvas.getObjects().forEach((obj) => {
          let objectOrigin = new fabric.Point(obj.left, obj.top)
          let new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, radians)
          obj.top = new_loc.y
          obj.left = new_loc.x
          obj.angle += degrees //rotate each object by the same angle
          obj.setCoords()
      });
      
      this.$refs.editor.canvas.renderAll();

    },
    async saveImage() {
      try {
        this.isLoading = true;
        let formData = new FormData();
        formData.append('HomeworkID', this.editedItem.HomeworkID);
        const dataUrl = this.$refs.editor.saveImage();
        const blob = new ImageDataConverter(dataUrl).dataURItoBlob();
        let response = null;
        formData.append('file', blob);
        if (this.editedItem.IsMarked)
        {  
          response = await httpClient.put('/homework/marking/upload', formData, {  
          headers: {'Content-Type': 'multipart/form-data'}
          });
        } else { 
          response = await httpClient.post('/homework/marking/upload', formData, { 
          headers: {'Content-Type': 'multipart/form-data'}
          });
        }
        
        if (response.status === 200)
        {
          this.$store.commit('updateHomework', { editedItem: this.editedItem, payload: response.data, hideAfterEdit: this.markedOption === "2" });
          this.snackbar = true;
          this.snackbarText = "Done. Your changes have been saved successfully.";
          this.snackbarColor = "success";
        } else {
          this.snackbar = true;
          this.snackbarText = "Sorry, unable to submit marking. Please refresh the page and try again.";
          this.snackbarColor = "error";
        }
        
      } catch(err) {
        this.snackbar = true;
        this.snackbarText = "Sorry, unable to submit marking. Please refresh the page and try again.";
        this.snackbarColor = "error";
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>
<style>
  canvas {
    border: 1px solid rgba(0,0,0,.13);
  }
  .color-container {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 5px 0;
  }
</style>