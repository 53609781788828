<template>
  <v-container grid-list-xl text-xs-center>
    <v-layout row wrap align-center>
      <v-flex xs12>
        <v-form ref="form">
          <v-card-text>
            <v-row v-if="allowRegionFilter">
              <v-select v-model="selectRegion" :items="regions" item-text="Name" item-value="RegionID" label="Region" prepend-icon="mdi-earth" @change="selectCentre = null; selectTerm = null; selectYear = null;" return-object clearable></v-select>
            </v-row>
            <v-row v-if="allowCentreFilter">
              <v-select v-model="selectCentre" :items="filteredCentres" item-text="Name" item-value="CentreID" label="Campus" prepend-icon="mdi-home-outline" return-object clearable></v-select>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="selectTerm" :items="filteredTerms" item-text="Name" item-value="TermID" label="Term" prepend-icon="mdi-calendar-range" return-object clearable></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="selectWeek" :items="weeks" item-text="Name" item-value="WeekID" label="Week/Day(Holiday)" prepend-icon="mdi-calendar-week" return-object clearable></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="selectYear" :items="filteredYears" item-text="FullName" item-value="Region_yearID" label="Year" prepend-icon="mdi-school-outline" return-object clearable></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="selectSubject" :items="filteredSubjects" item-text="Name" item-value="SubjectID" label="Subject" prepend-icon="mdi-flask-empty-outline" return-object clearable></v-select>  
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="fromdate"
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="fromDateFormatted"
                      label="From date submitted"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      @blur="fromdate = parseDate(fromDateFormatted)"
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="fromdate" no-title @input="menuFromDate = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="todate"
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="toDateFormatted"
                      label="To date submitted"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      @blur="todate = parseDate(toDateFormatted)"
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="todate" no-title @input="menuToDate = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="studentid" prepend-icon="mdi-magnify" label="Student ID" clearable></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-radio-group v-model="markedOption" row>
                <template v-slot:label>
                  <div>Show marked option: </div>
                </template>
                <v-radio label="Marked" value="1"></v-radio>
                <v-radio label="Not marked" value="2"></v-radio>
                <v-radio label="Both marked and not marked" value="3"></v-radio>
              </v-radio-group>
            </v-row>
            <v-row>
              <v-btn color="primary" class="mr-4" @click="Search" :loading="searchloading">Search</v-btn>
            </v-row>
          </v-card-text>
        </v-form>  
      </v-flex>
      <v-flex xs12 v-if="showTable">
        <v-card>
          <v-card-title>
            Homework Marking
          </v-card-title>
          <v-card-subtitle>
            {{ searchTitle}}
          </v-card-subtitle>
          <v-data-table multi-sort :headers="headers" :items="homeworks" item-key="name" :options.sync="options" :server-items-length="total" :footer-props="{ 'items-per-page-options': [10, 20, 50, 100]}" :loading="isLoading" loading-text="Loading... Please wait">
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchKey"
                    label="Search"
                    append-outer-icon="mdi-magnify"
                  ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:[`item.StudentName`]="{ item }">
              <span>{{ item && item.GivenName }} {{ item && item.Surname }}</span>
            </template>
            <template v-slot:[`item.SubmittedOn`]="{ item }">
              <span>{{ item.SubmittedOn | formatDateTime }}</span>
            </template>
            <template v-slot:[`item.MarkedOn`]="{ item }">
              <span>{{ item.MarkedOn | formatDateTime }}</span>
            </template>
            <template v-slot:[`item.AwardedOn`]="{ item }">
              <span>{{ item.AwardedOn | formatDateTime }}</span>
            </template>
            <template v-slot:[`item.homework`]="{ item }">
              <v-badge bordered color="green" icon="mdi-check" :value="item.IsMarked" overlap>
                <v-btn class="mx-2" fab dark small color="primary" :loading="item.downloadloading" :disabled="item.downloadloading" @click="ShowHomeworkDialog(item)">
                  <v-icon dark>mdi-file-edit-outline</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <template v-slot:[`item.award`]="{ item }">
              <v-badge bordered color="green" icon="mdi-check" :value="!!(item.AwardID)" overlap>
                <v-btn class="mx-2" fab small color="orange" :loading="item.downloadloading" :disabled="!item.IsAwardEnabled" @click="ShowAwardDialog(item)">
                  <v-icon color="white">mdi-certificate-outline</v-icon>
                </v-btn>
              </v-badge>  
            </template>
          </v-data-table>
        </v-card>       
      </v-flex>
      <v-dialog v-model="showHomeworkDialog" fullscreen hide-overlay>
        <v-toolbar dark color="red darken-4">
          <v-hover v-slot:default="{ hover }">
            <div @click="close">
              <v-icon large :color="hover? 'grey' : 'white'" >mdi-close</v-icon>
            </div>
           </v-hover>
          <v-toolbar-title>Homework Marking</v-toolbar-title>
        </v-toolbar>
        <edit-image :markedOption="markedOption" :editedItem="selectedItem" @close="close" @cancel="close" :key="`homework.${selectedItem.HomeworkID}`"></edit-image>
      </v-dialog>
      <v-dialog v-model="showAwardDialog" fullscreen hide-overlay>
        <v-toolbar dark color="red darken-4">
          <v-icon @click="close" large>mdi-close</v-icon>
          <v-toolbar-title>Good Student Award</v-toolbar-title>
        </v-toolbar>
        <edit-award :Homework="selectedItem" @close="close" @cancel="close" :key="`award.${selectedItem.HomeworkID}`"></edit-award>
      </v-dialog>   
      <div class="text-center">
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </div> 
    </v-layout>
  </v-container>
</template>

<script>
import EditImage from '@/components/EditImage'
import EditAward from '@/components/EditAward'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { httpClient } from '../services/http-client';

export default {
  name: 'HomeworkMarking',
  components: {
    EditImage,
    EditAward,
  },
  data () {
    return {
      headers: [
        { text: 'Marking', value: 'homework', sortable: false, width: "50" },
        { text: 'Good Student Award', value: 'award', sortable: false, width: "50" },
        {
          text: 'ID',
          align: 'left',
          value: 'HomeworkID',
          width: "50" 
        },
        { text: 'Campus', value: 'CentreName',width: "50" }, 
        { text: 'Term', value: 'TermName', width: "50" }, 
        { text: 'Week/ Day (Hol)', value: 'WeekID', width: "50" },
        { text: 'Year', value: 'HomeworkYear', width: "50" },
        { text: 'Subject', value: 'SubjectName', width: "50" },
        { text: 'Student ID', value: 'StudentID', width: "50" },
        { text: 'Student Name', value: 'StudentName', width: "50" },
        { text: 'Date Submitted', value: 'SubmittedOn', width: "50" },
        { text: 'File Name', value: 'FileName', width: "50" },
        { text: 'Date Marked', value: 'MarkedOn', width: "50" },
        { text: 'Marked By', value: 'TeacherName', width: "50" },
        { text: 'Date Awarded', value: 'AwardedOn', width: "50" },
        { text: 'Awarded By', value: 'AwardedBy', width: "50" },
      ],
      weeks: [
        {Name: '1', WeekID: 1}, 
        {Name: '2', WeekID: 2}, 
        {Name: '3', WeekID: 3},
        {Name: '4', WeekID: 4},
        {Name: '5', WeekID: 5},
        {Name: '6', WeekID: 6},
        {Name: '7', WeekID: 7},
        {Name: '8', WeekID: 8},
        {Name: '9', WeekID: 9},
        {Name: '10', WeekID: 10}
      ],
      searchKey: "",
      awaitingSearch: false,
      timeout: null,
      isLoading: false,
      showHomeworkDialog: false,
      showAwardDialog: false,
      overlay: false,
      selectedItem: { Term: '', WeekID: '', YearID: '', HomeworkID: '', Student: {GivenName: '', Surname: ''}},
      selectTerm: null,
      selectWeek: null,
      selectYear: null,
      selectSubject: null,
      searchTitle: '',
      searchloading: false,
      showTable: false,
      selectRegion: null,
      selectCentre: null,
      fromdate: null,
      fromDateFormatted: null,
      menuFromDate: false,
      todate: null,
      toDateFormatted: null,
      menuToDate: false,
      markedOption: "2",
      studentid: null,
      options: {},
      total: 0,
    }
  },
  computed: {
    filteredCentres() {
      if (this.selectRegion)
        return this.centres.filter(c => c.RegionID === this.selectRegion.RegionID);
      return this.centres;
    },
    filteredTerms() {
      if (this.selectRegion)
        return this.terms.filter(c => c.RegionID === this.selectRegion.RegionID);
      return this.terms;
    },
    filteredYears() {
      if (this.selectTerm && this.selectTerm.Name.toLowerCase().includes("(au)")) {
        return this.years.filter(y => y.KeyName.toLowerCase().includes("au-"));
      } else if (this.selectTerm && this.selectTerm.Name.toLowerCase().includes("(hk)")) {
        return this.years.filter(y => y.KeyName.toLowerCase().includes("hk-"));  
      } else if (this.selectRegion) {
        return this.years.filter(c => c.RegionID === this.selectRegion.RegionID);
      }
      return this.years;
    },
    filteredSubjects() {
      if (this.selectTerm && this.selectTerm.Name.toLowerCase().includes("(au)")) {
        return this.subjects.filter(s => !s.RegionID);
      } else if (this.selectTerm && this.selectTerm.Name.toLowerCase().includes("(hk)")) {  
        return this.subjects.filter(s => s.RegionID === 8);
      }
      return this.subjects;
    },
    computedFromDateFormatted () {
      return this.formatDate(this.fromdate)
    },
    computedToDateFormatted () {
      return this.formatDate(this.todate)
    },
    ...mapGetters(['allowRegionFilter', 'allowCentreFilter']),
    ...mapState(['user', 'homeworks', 'terms', 'years', 'subjects', 'awards', 'regions', 'centres'])
  },
  watch: {
    fromdate () {
      this.fromDateFormatted = this.formatDate(this.fromdate)
    },
    todate () {
      this.toDateFormatted = this.formatDate(this.todate)
    },
    options: {
      handler () {
        if (this.total > 0) {
          this.Search();
        }
      },
      deep: true,
    },
    searchKey () {
      if (!this.awaitingSearch) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.Search();
          this.awaitingSearch = false;
        }, 2000); // 2 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  mounted () {
    this.initialize();
  },
  beforeDestroy: function(){
    clearTimeout(this.timeout);
  },
  methods: {
    async initialize() {
      try {
        this.overlay = true;
        await Promise.all([this.$store.dispatch('getTerms'), 
        this.$store.dispatch('getSubjects'), 
        this.$store.dispatch('getYears'),
        this.$store.dispatch('getRegions'),
        this.$store.dispatch('getCentres')]);
      } finally {
        this.overlay = false;
      }
    },
    async Search() {
      let titles = [];
      if (this.selectTerm) titles.push(this.selectTerm.Name);
      if (this.selectWeek) titles.push(`Week/ Day(Holiday) ${this.selectWeek.Name}`);
      if (this.selectYear) titles.push(this.selectYear.FullName);
      if (this.selectSubject) titles.push(this.selectSubject.Name);
      this.searchTitle = titles.join() || 'All homeworks';

      this.showTable = true;
      this.isLoading = true;
      this.searchloading = true;
      try
      {
        const params = {
          RegionID: this.selectRegion && this.selectRegion.RegionID,
          CentreID: this.selectCentre && this.selectCentre.CentreID,
          TermID: this.selectTerm && this.selectTerm.TermID,
          WeekID: this.selectWeek && this.selectWeek.WeekID,
          Region_yearID: this.selectYear && this.selectYear.Region_yearID,
          SubjectID: this.selectSubject && this.selectSubject.SubjectID,
          FromDate: this.fromdate && this.fromdate.toString(),
          ToDate: this.todate && this.todate.toString(),
          MarkedOption: this.markedOption,
          StudentID: this.studentid,
          limit: this.options.itemsPerPage,
          offset: this.options.page,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          search: this.searchKey,
        };
        const response = await httpClient.get('/homework/marking', { params });
        let homeworks = [];
        if (response && response.data) {
          homeworks = response.data.homeworks;
          this.total = response.data.total;
        }
        this.$store.commit('setHomeworks', homeworks);
      } finally {
        this.searchloading = false;
        this.isLoading = false;
      }
    },
    ShowHomeworkDialog(item) {
      this.showHomeworkDialog = true;
      this.selectedItem = item;
    },
    ShowAwardDialog(item) {
      this.showAwardDialog = true;
      this.selectedItem = item;
    },
    close() {
      this.showHomeworkDialog=false;
      this.showAwardDialog=false
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>

<style scoped></style>
