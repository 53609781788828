<template>
  <v-card>
    <v-card-title v-if="editedItem">
      {{editedItem.Term && editedItem.Term.Name}}, Week {{editedItem.WeekID}}, {{editedItem.Region_Year && editedItem.Region_Year.FullName}}, {{editedItem.Subject && editedItem.Subject.Name}}
    </v-card-title>
    <v-card-subtitle v-if="editedItem">
      Student Number: {{editedItem.StudentID}}, Student Name: {{editedItem.Student && editedItem.Student.GivenName}} {{editedItem.Student && editedItem.Student.Surname}}
    </v-card-subtitle>
    <v-card-text>
      <v-alert v-if="!allowCreateOrUpdate" type="info">Award has already been granted for the same Term, Week, Year and Subject. </v-alert>
      <div v-if="allowCreateOrUpdate">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="studentFirstName" :counter="16" maxlength="16" :rules="studentFirstNameRules" label="Student's First Name" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="studentSurname" :counter="16" maxlength="16" :rules="studentSurnameRules" label="Student's Surname" required></v-text-field>
            </v-col>
          </v-row>    
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="teacherName" :counter="16" :rules="teacherNameRules" maxlength="16" label="Teacher's Name" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="comments" :counter="16" maxlength="16" label="Comments"></v-text-field>
            </v-col>
          </v-row>     
          <v-row>
            <v-col cols="12">
              <header>Choose a design for award</header>
              <v-item-group active-class="primary" v-model="selectedDesign" mandatory>
                <v-container>
                  <v-row>
                    <v-col v-for="card in cards" :key="card.title" :cols="card.flex" md="4">
                      <v-item v-slot:default="{ active, toggle }">
                        <v-card :color="active ? 'primary' : ''" class="d-flex align-center" dark height="200" @click="toggle">
                          <v-img :src="card.src" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
                            <v-card-title v-text="card.title"></v-card-title>
                              <v-scroll-y-transition>
                                <div v-if="active" class="display-3 flex-grow-1 red darken-2 text-center">
                                  <v-icon>mdi-heart</v-icon>
                                </div>
                              </v-scroll-y-transition>
                          </v-img>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-col>  
          </v-row>
        </v-form>
      </div>  
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" v-if="allowCreateOrUpdate" :loading="isSaving" @click="Save">{{editedItem && editedItem.AwardID ? 'Update' : 'Create'}}</v-btn>
      <v-btn text @click="close">Close</v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ snackbarText }}
    </v-snackbar>
    <div class="text-center">
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>  
  </v-card>
</template>

<script>
import { httpClient } from '../services/http-client';
import { mapState } from 'vuex'

export default {
  name: 'EditAward',
  props: {
    Homework: Object,
  },
  data () {
    return {
      cards: [
        { title: 'Congrats', src: require('@/assets/static/images/Good Student Award 1.png'), flex: 6 },
        { title: 'Excellent Work', src: require('@/assets/static/images/Good Student Award 2.png'), flex: 6 },
        { title: 'Well Done', src: require('@/assets/static/images/Good Student Award 3.png'), flex: 6 },
        { title: 'Great Work 1', src: require('@/assets/static/images/Good Student Award 4.png'), flex: 6 },
        { title: 'Top Job', src: require('@/assets/static/images/Good Student Award 5.png'), flex: 6 },
        { title: 'Great Work 2', src: require('@/assets/static/images/Good Student Award 6.png'), flex: 6 },
        { title: 'Nice Work', src: require('@/assets/static/images/Good Student Award 7.png'), flex: 6 },
      ],
      teacherNameRules: [
        v => !!v || 'Teacher\' Name is required',
        v => (v && v.length <= 16) || 'Teacher\'s Name must be less than 16 characters',
      ],
      studentFirstNameRules: [
        v => !!v || 'Student\' First name is required',
        v => (v && v.length <= 16) || 'Teacher\'s Name must be less than 16 characters',
      ],
      studentSurnameRules: [
        v => !!v || 'Student\' Surname is required',
        v => (v && v.length <= 16) || 'Teacher\'s Name must be less than 16 characters',
      ],
      rules: [
        v => !!v || 'File is required',
        v => !v || v.size < 2000000 || 'File size should be less than 2 MB!',
      ],
      teacherName: null,
      studentFirstName: null,
      studentSurname: null,
      localSubject: null,
      valid: true,
      isLoading: false,
      isSaving: false,
      localComments: null,
      selectedDesign: 0,
      allowCreateOrUpdate: false,
      editedItem: null,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      timeout: 6000,
    }
  },
  async mounted() {
    try {
      this.isLoading = true;
      if (this.Homework && this.Homework.HomeworkID) {
        const response = await httpClient.get(`/homework/award/${this.Homework.HomeworkID}`);
        this.allowCreateOrUpdate = response.data.allowCreateOrUpdate;
        this.editedItem = response.data.homework;
        let tname = '';
        if (this.editedItem && this.editedItem.Award)
          tname = this.editedItem.Award.TeacherName;
        else if (this.user)
          tname = this.user.ShortName || this.user.GivenName;  
        this.teacherName = tname.substring(0, 16);

        let sfname = "";
        if (this.editedItem && this.editedItem.Award)
          sfname = this.editedItem.Award.StudentFirstName;  
        else if (this.editedItem)
          sfname = this.editedItem.Student.GivenName;  
        this.studentFirstName = sfname.substring(0, 16);
        
        let ssname = "";
        if (this.editedItem && this.editedItem.Award)
          ssname = this.editedItem.Award.StudentSurname;  
        else if (this.editedItem)
          ssname = this.editedItem.Student.Surname; 
        this.studentSurname = ssname.substring(0, 16); 

        let text = "";
        if (this.editedItem && this.editedItem.Award)
          text = this.editedItem.Award.Comments;  
        this.comments = text.substring(0, 16);

        if (this.editedItem && this.editedItem.Award)
          this.selectedDesign = this.editedItem.Award.DesignID - 1;  
      }
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    async Save() {
      if (this.$refs.form.validate()) {
        try {
          this.isSaving = true;
          let response = null;
          const params = {
            HomeworkID: this.editedItem.HomeworkID,
            StudentFirstName: this.studentFirstName,
            StudentSurname: this.studentSurname,
            TeacherName: this.teacherName,
            Comments: this.comments,
            DesignID: this.selectedDesign + 1
          }
          if (this.editedItem && this.editedItem.AwardID) {
            response = await httpClient.put('/homework/award', params);
          } else {
            response = await httpClient.post('/homework/award', params);
          }

          if (response.status === 200)
          {
            this.$store.commit('updateAwardHomework', { payload: response.data });
            this.snackbar = true;
            this.snackbarText = "Done. Your changes have been saved successfully.";
            this.snackbarColor = "success";
          } else {
            this.snackbar = true;
            this.snackbarText = "Sorry, unable to update award. Please refresh the page and try again.";
            this.snackbarColor = "error";
          }
        } catch(err) {
          this.snackbar = true;
          this.snackbarText = "Sorry, unable to update award. Please refresh the page and try again.";
          this.snackbarColor = "error";
        } finally {
          this.isSaving = false;
        }
      }
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>
